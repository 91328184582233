import LoginBtn from "./LoginBtn";
import RegisterBtn from "./RegisterBtn";

function AccountActions() {
    return (
        <div>
            <LoginBtn />
            <RegisterBtn />
        </div>
    )
}

export default AccountActions