import { Link } from "react-router-dom";
import { format } from "date-fns";

import classes from "./BlogPost.module.css";

function BlogPost({
  _id,
  title,
  summary,
  content,
  cover,
  createdAt,
  author,
  vert,
}) {
  // const server = "http://localhost:4000";
  // const server = "https://sunshine-website-staging.onrender.com";
  const server = process.env.REACT_APP_SERVER;

  return (
    <div className={classes.container}>
      {!vert ? (
        <div className={classes.post}>
          <div className={classes.img}>
            <Link to={`/post/${_id}`}>
              <img src={cover} alt=""/>
            </Link>
          </div>
          <div className={classes.postInfo}>
            <Link to={`/post/${_id}`}>
              <h2 className={classes.title}>{title}</h2>
            </Link>
            <p className={classes.summary}>{summary}</p>
            <p className={classes.author}>by {author.username}</p>
            <time className={classes.time}>
              {format(new Date(createdAt), "ccc MMM d, yyyy h:mm a")}
            </time>
          </div>
        </div>
      ) : (
        <div className={classes.postVert}>
          <Link className={classes.link} to={`/post/${_id}`}>
            <div className={classes.img}>
              <img src={cover} alt="" />
            </div>
            <div className={classes.postInfo}>
              <h2 className={classes.title}>{title}</h2>
              <p className={classes.summary}>{summary}</p>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default BlogPost;
