import classes from "./Socials.module.css";

function Socials({ socialLinks }) {
  if (!socialLinks || socialLinks.length === 0) {
    return null;
  }

  return (
    <div className={classes.socials}>
      {socialLinks.map((item) => (
        <a
          className={classes.link}
          key={item.platform}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={classes.img}
            src={require(`../assets/${item.platform}.png`)}
            alt={`../assets/${item.platform}.png`}
          />
        </a>
      ))}
    </div>
  );
}

export default Socials;
