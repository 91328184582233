import Socials from "./Socials";
import SocialsReverse from "./SocialsReverse"

import classes from "./TeamBio.module.css";

function TeamBio({ name, username, headshot, body, socialLinks, layout }) {
  return (
    <div className={classes.container}>
      {layout === 0 ? (
        <div className={classes.bio}>
          <div className={classes.img}>
            <img src={headshot} alt="" />
          </div>
          <div className={classes.info}>
            <div className={classes.names}>
              <div className={classes.name}>{name}</div>
              <div className={classes.username}>@{username}</div>
            </div>
            <p className={classes.body}>{body}</p>
            <div className={classes.socials}>
              <Socials socialLinks={socialLinks}/>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.bioAlt}>
          <div className={classes.info}>
            <div className={classes.names}>
              <div className={classes.name}>{name}</div>
              <div className={classes.username}>@{username}</div>
            </div>
            <p className={classes.body}>{body}</p>
            <div className={classes.socials}>
              <SocialsReverse socialLinks={socialLinks}/>
            </div>
          </div>
          <div className={classes.img}>
            <img src={headshot} alt="" />
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamBio;
