import { Link } from "react-router-dom";

import classes from "./AdminOptions.module.css";

function AdminOptions(props) {
  const { onClick } = props;
  return (
    <div className={classes.container}>
      <ul className={classes.ul}>
        <li className={classes.li}>
          <Link to="/create" className={classes.link} onClick={onClick}>
            New Blog
          </Link>
        </li>
        <li>
          <Link to="/poll" className={classes.link} onClick={onClick}>
            New Poll
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default AdminOptions;
