import { Link } from "react-router-dom";

import classes from "./HomeBtn.module.css";
import InAppLogo from "../assets/inAppLogo.svg"

function HomeBtn() {
    return (
        <button className={classes.btn}>
            <Link to="/" className={classes.link}>
                <img src={InAppLogo} alt="InAppLogo" />
            </Link>
        </button>
    )
}

export default HomeBtn
