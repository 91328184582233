import { useState } from "react";
import classes from "./FAQ.module.css";

function FAQ({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = (itemId) => {
    setIsOpen((prevIsOpen) => ({
        ...prevIsOpen,
        [itemId]: !prevIsOpen[itemId]
    }));
  }

  return (
    <div className={classes.faq}>
      {data.map((faq) => (
        <div className={classes.question} key={faq.id} onClick={() => toggleFAQ(faq.id)}>
          <h3>Q: {faq.question}</h3>
          {isOpen[faq.id] && <p className={classes.answer}>A: {faq.answer}</p>}
        </div>
      ))}
    </div>
  );
}

export default FAQ;
