import PageHeader from '../components/PageHeader'
import BlogList from '../components/BlogList';

function BlogPage() {
    const text = "News"
    
    return <div>
        <PageHeader text={text} />
        <BlogList/>
    </div>;
}

export default BlogPage;
