import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../UserContext";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const { setUserInfo } = useContext(UserContext);
  const server = process.env.REACT_APP_SERVER;

  async function login(ev) {
    ev.preventDefault();
    const res = await fetch(`${server}/login`, {
    method: "POST",
    body: JSON.stringify({ username, password }),
    headers: { "Content-Type": "application/json" },
    credentials: 'include',
    });
    if (res.ok) {
    res.json().then(userInfo => {
    setUserInfo(userInfo);
    
    
    const redirectPath = localStorage.getItem('redirectPath') || '/';
    setRedirect(redirectPath);
    localStorage.removeItem('redirectPath');
    });
    } else {
    alert('wrong credentials');
    }
    }    

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <form className="login" onSubmit={login}>
      <h3>Login</h3>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(ev) => setUsername(ev.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(ev) => setPassword(ev.target.value)}
      />
      <button>Login</button>
    </form>
  );
}

export default LoginPage;
