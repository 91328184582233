import { useEffect, useState } from "react";

import classes from "./UserCount.module.css";

function UserCount({ goalAmount }) {
  const [userCount, setUserCount] = useState(0);
  // const server = "http://localhost:4000";
  // const server = "https://sunshine-website-staging.onrender.com";
  const server = process.env.REACT_APP_SERVER;

  useEffect(() => {
    fetch(`${server}/user/count`).then((res) => {
      res.json().then((data) => {
        setUserCount(data.count);
      });
    });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.fraction}>
        <div className={classes.count}>{userCount}</div>
        <hr className={classes.line} />
        <div className={classes.goal}>{goalAmount}</div>
      </div>
      <div className={classes.text}>Rays of Positivity</div>
    </div>
  );
}

export default UserCount;
