import { useState } from "react";
import { Navigate } from "react-router-dom";

import Editor from "../components/Editor";

function CreatePollPage() {
  const [title, setTitle] = useState("");
  const [intro, setIntro] = useState("");
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([{ optionText: "", votes: 0 }]);
  const [redirect, setRedirect] = useState(false);
  // const server = "http://localhost:4000";
  // const server = "https://sunshine-website-staging.onrender.com";
  const server = process.env.REACT_APP_SERVER;

  const handleAddOption = () => {
    setOptions([...options, { optionText: "", votes: 0 }]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  async function createNewPoll(ev) {
    ev.preventDefault();
    /*
    const pollData = {
        title,
        intro,
        question,
        options: options.map((option) => ({
            optionText: option.optionText,
            votes: option.votes,
        })),
    };
*/
    const res = await fetch(`${server}/poll`, {
      method: "POST",
      body: JSON.stringify({
        title,
        intro,
        question,
        options,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (res.ok) {
      setRedirect(true);
    }
  }

  if (redirect) {
    return <Navigate to={"/support"} />;
  }

  return (
    <form className="pollForm" onSubmit={createNewPoll}>
      <div className="pollHeader">
        <h3>Create New Poll</h3>
      </div>
      <div className="pollInfo">
        <input
          type="title"
          placeholder={"Poll Title"}
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
        />
      </div>
      <div className="editor">
        <Editor value={intro} onChange={setIntro} />
      </div>
      <div className="pollInfo">
        <input
          type="question"
          placeholder={"Poll Question"}
          value={question}
          onChange={(ev) => setQuestion(ev.target.value)}
        />
      </div>
      <div className="pollOptions">
        <h3>Poll Options:</h3>
        {options.map((option, index) => (
          <div key={index} className="optionGroup">
            <input
              type="text"
              placeholder={`Option ${index + 1}`}
              value={option.optionText}
              onChange={(ev) => {
                const newOptions = [...options];
                newOptions[index].optionText = ev.target.value;
                setOptions(newOptions);
              }}
            />
            <button type="button" onClick={() => handleRemoveOption(index)}>
              Remove Option
            </button>
          </div>
        ))}
        <button
          className="addOptionBtn"
          type="button"
          onClick={handleAddOption}
        >
          Add Option
        </button>
      </div>
      <button>Submit Poll</button>
    </form>
  );
}

export default CreatePollPage;
