import { HashLink } from "react-router-hash-link";
import PageHeader from "../components/PageHeader";
import TextBlock from "../components/TextBlock";

import foundation from "../data/json/foundation-mission.json";
import { Link } from "react-router-dom";

function SunShineFoundationPage() {
  return (
    <div>
      <PageHeader text="The SunShine Foundation" />
      <TextBlock text={foundation.mission} />
      <h1>
        Click{" "}
        <HashLink className="hashLink" smooth to="/about#team">
          here
        </HashLink>{" "}
        to meet the team!
      </h1>
      <h2 className="pubdocscta">
        Check out The Sunshine Foundation's public documents{" "}
        <Link className="link" to="/foundation-documents">
          here!
        </Link>
      </h2>
    </div>
  );
}

export default SunShineFoundationPage;
