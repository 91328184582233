import classes from "./ImageSlider.module.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";

const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [activeIdx, setActiveIdx] = useState(0);
  const timerRef = useRef(null);
  const length = slides.length;

  const nextSlide = useCallback(() => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    setActiveIdx(current === length - 1 ? 0 : current + 1);
  }, [current, length]);

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    setActiveIdx(current === length - 1 ? 0 : current + 1);
  };

  const goToSlide = (current) => {
    setCurrent(current);
    setActiveIdx(current);
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      nextSlide();
    }, 5000);

    return () => clearTimeout(timerRef.current)
  }, [nextSlide]);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  };

  return (
    <div className={classes.slider}>
      <FaArrowAltCircleLeft className={classes.leftArrow} onClick={prevSlide} />
      <FaArrowAltCircleRight
        className={classes.rightArrow}
        onClick={nextSlide}
      />
      <div className={classes.content}>
        {slides.map((slide, index) => {
          return (
            <div
              className={index === current ? classes.activeSlide : classes.slide}
              key={index}
            >
              {index === current && (
                <h2 className={classes.caption}>{slide.caption}</h2>
              )}
            </div>
          );
        })}
        {slides.map((slide, index) => {
          return (
            <div
              className={index === current ? classes.activeSlide : classes.slide}
              key={index}
            >
              {index === current && (
                <img src={slide.id} alt={slide.alt} className={classes.image} />
              )}
            </div>
          );
        })}
        <div className={classes.dotsContainer}>
          {slides.map((slide, index) => (
            <div
              className={`${classes.dot} ${index === activeIdx ? classes.activeDot : classes.dimDot}`}
              key={index}
              onClick={() => {
                goToSlide(index);
                setActiveIdx(index);
              }}
            >
              ●
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
