import NavBtn from "./NavBtn";
import HomeBtn from "./HomeBtn";
import AccountActions from "./AccountActions";
import LogoutBtn from "./LogoutBtn";
import AdminOptionsButton from "./AdminOptionsButton";
import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContext";

import classes from "./Header.module.css";
import { HashLink } from "react-router-hash-link";

function Header() {
  const { setUserInfo, userInfo } = useContext(UserContext);
  // const server = "http://localhost:4000";
  // const server = "https://sunshine-website-staging.onrender.com";
  const server = process.env.REACT_APP_SERVER;

  useEffect(() => {
    fetch(`${server}/profile`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        res
          .json()
          .then((userInfo) => {
            // ("Received user info from /profile:", userInfo); // LOG
            setUserInfo(userInfo);
          })
          .catch((err) => {
            console.log("Profile fetch JSON parsing error", err); // LOG
          });
      })
      .catch((err) => {
        console.log("Error fetching /profile:", err); // LOG
      });
  }, []);

  async function logout() {
    try {
      const res = await fetch(`${server}/logout`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.ok) {
        setUserInfo(null);
        //window.location.reload();
      } else {
        console.error("Logout failed", await res.json());
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  }

  /*
  useEffect(() => {
    if (userInfo === null) {
      window.location.reload();
    }
  }, [userInfo]);
  */

  async function logout() {
    try {
      const response = await fetch(`${server}/logout`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setUserInfo(null); // This will trigger the useEffect above when userInfo becomes null
      } else {
        console.error("Logout failed", await response.json());
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  const username = userInfo?.username;
  const userRole = userInfo?.role;

  return (
    <div className={classes.appHeader}>
      <div className={classes.leftSide}>
        <NavBtn className={classes.navBtn} />
        <HomeBtn className={classes.homeBtn} />
      </div>
      <div className={classes.rightSide}>
        <button className={classes.donateBtn}>
          <HashLink className={classes.hashlink} smooth to="/support#donate">Donate Now!</HashLink>
        </button>
        {username && (
          <div className={classes.rightSide}>
            {userRole === "admin" && (
              <div>
                <AdminOptionsButton className={classes.adminBtn}/>
              </div>
            )}
            <LogoutBtn onClick={logout} className={classes.logoutBtn} />
          </div>
        )}
        {!username && (
          <div>
            <AccountActions className={classes.accActions} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
