import classes from "./Modal.module.css"

function Modal(props) {
    return (
      <div className={classes.modalBackdrop}>
        <div className={classes.modal}>
          {props.children}
        </div>
      </div>
    );
  }

export default Modal;