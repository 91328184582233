import classes from "./IntroList.module.css";

function IntroList({ data }) {
  return (
    <div className={classes.intro}>
      {data.map((section) => (
        <div className={classes.container} key={section.subheading}>
          <h2 className={classes.subheading}>
            {section?.subheading}
          </h2>
          <p>{section?.content}</p>
        </div>
      ))}
    </div>
  );
}

export default IntroList;
