import { useState } from "react";
import { Navigate } from "react-router-dom";

function RegisterPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const role = "user";
  // const server = "http://localhost:4000";
  // const server = "https://sunshine-website-staging.onrender.com";
  const server = process.env.REACT_APP_SERVER;

  async function register(ev) {
    ev.preventDefault();
    const res = await fetch(`${server}/register`, {
      method: "POST",
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        username,
        password,
        role,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      alert("registration successful");
      const redirectPath = localStorage.getItem('redirectPath') || '/';
      setRedirect(redirectPath);
      localStorage.removeItem('redirectPath');
    } else {
      alert("registration failed");
    }
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <form className="register" onSubmit={register}>
      <h3>Register</h3>
      <div className="name-fields">
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(ev) => setFirstName(ev.target.value)}
        />
        <input
          type="text"
          placeholder="Last Name"
          className="lastName"
          value={lastName}
          onChange={(ev) => setLastName(ev.target.value)}
        />
      </div>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(ev) => setEmail(ev.target.value)}
      />
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(ev) => setUsername(ev.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(ev) => setPassword(ev.target.value)}
      />
      <button>Register</button>
    </form>
  );
}

export default RegisterPage;
