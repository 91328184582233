import AdminOptions from "./AdminOptions";

import classes from "./AdminMenu.module.css";

function AdminMenu(props) {
    const { onClick } = props;

    return (
        <div className={classes.adminMenu}>
            <div className={classes.background} />
            <AdminOptions  className={classes.AdminOptions} onClick={onClick}/>
        </div>
    )
};

export default AdminMenu;