import classes from "./NavMenu.module.css";

import MainNavigation from "./layout/MainNavigation";

function NavMenu(props) {
    const { onClick } = props;

    return (
        <div className={classes.navmenu}>
            <div className={classes.background} />
            <MainNavigation className={classes.MainNavigation} onClick={onClick}/>
        </div>
    );
}

export default NavMenu;
