import PageHeader from "../components/PageHeader";
import venmo from "../assets/venmo.png";
import paypal from "../assets/paypal.png";
import ProgressBar from "../components/ProgressBar";
import { FaArrowDown } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
// import { Link } from "react-router-dom";
import LatestPoll from "../components/LatestPoll";

function SupportPage() {
  // const { setUserInfo, userInfo } = useContext(UserContext);
  // const userRole = userInfo?.role;

  return (
    <div>
      <PageHeader text="Support Us" />
      <h2 className="subheading">Your Generosity, Our Commitment</h2>
      <div className="bodyContainer">
        <p className="body" id="support">
          Your support matters. Donations and funding play a crucial role in our
          efforts. At the Sunshine Foundation, we uphold transparency and
          accountability. Every donation is carefully utilized to advance our
          programs and services, with strict financial oversight to maximize
          impact. We keep our donors informed with regular reports, showcasing
          the tangible outcomes of their generosity.
        </p>
        <p className="body" id="supportQuestions">
        Have questions about The Sunshine Foundation? Check out the{" "}
        <HashLink className="hashLink" smooth to="/faq#foundation">
          FAQs
        </HashLink>
      </p>
      </div>
      <ProgressBar className="progressBar" current={6500} goal={10000} />
      <div id="container">
        <h1 className="centeredSub" id="donate">Donate Now!</h1>
        <FaArrowDown className="arrow" size={50} />
      </div>
      <div className="paymentButtons">
        <button className="payment" id="venmo">
          <a href="" target="_blank" rel="noopener noreferrer">
            <img src={venmo} alt="Venmo"></img>
          </a>
        </button>
        <button className="payment" id="paypal">
          <a href="" target="_blank" rel="noopener noreferrer">
            <img
              src={paypal}
              alt="PayPal"
            ></img>
          </a>
        </button>
      </div>
      <LatestPoll />
    </div>
  );
}

export default SupportPage;
