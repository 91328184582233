import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Chart } from "chart.js/auto";
import Modal from "./Modal";
import { UserContext } from "../UserContext";
import classes from "./LatestPoll.module.css";

function isEmpty(obj) {
  return obj == null || Object.keys(obj).length === 0 && obj.constructor === Object;
}

function LatestPoll() {
  const [poll, setPoll] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  //const [user, setUser] = useState(null);
  const [userVoted, setUserVoted] = useState(false);
 
  const { userInfo } = useContext(UserContext);
  const server = process.env.REACT_APP_SERVER;

  const navigate = useNavigate();

  const handleLinkClick = (path) => {
    localStorage.setItem('redirectPath', '/support')
    navigate(path);
  };

  function generateChartData(poll, selectedOptionId) {
    const labels = poll.options.map((option) => option.optionText);
    const data = poll.options.map((option) => option.votes);
    const backgroundColors = [
      "rgba(255, 99, 132, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(255, 205, 86, 0.6)",
      "rgba(201, 203, 207, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      // ... Add more colors if you can have more options
    ];
    /*
    const hoverOffsets = poll.options.map(
      (option) => {
        console.log("option._id:", option._id, typeof option._id);
        console.log(
          "selectedOptionId:",
          selectedOptionId,
          typeof selectedOptionId
        );
        console.log(option._id === selectedOptionId);
        return option._id === selectedOptionId ? 10 : 0;
      }

      //(option) => (option._id === selectedOptionId ? 10 : 0) // Increase the number to make it pop out more
    );
    */

    const offsets = poll.options.map((option) =>
      option._id === selectedOptionId ? 100 : 0
    );

    return {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: backgroundColors,
          offset: offsets,
        },
      ],
    };
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const pollRes = await fetch(`${server}/poll/latest`);

        // let userData = null;
        let latestPoll = null;
        /*
        if (profileRes.ok) {
          userData = await profileRes.json();
          setUser(userData);
        }
        */

        if (pollRes.ok) {
          latestPoll = await pollRes.json();
          setPoll(latestPoll);
        }

        if (!userInfo) {
          setUserVoted(false);
          setSelectedOption(null);
          return;
        }

        if (userInfo && latestPoll) {
          const userVote = latestPoll.votedUsers.find(
            (vote) => vote.userId === userInfo.id
          );

          if (userVote) {
            setUserVoted(true);
            const selected = latestPoll.options.find(
              (opt) => opt._id.toString() === userVote.optionId.toString()
            );
            setSelectedOption(selected);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (userVoted && poll && selectedOption) {
      const ctx = document.getElementById("myPieChart").getContext("2d");
      const chartData = generateChartData(poll, selectedOption._id);
      const pieChart = new Chart(ctx, {
        type: "pie",
        data: chartData,
      });
      return () => {
        pieChart.destroy();
      };
    }
  }, [userVoted, poll, selectedOption]);

  async function submitVote() {
    if (!selectedOption || userVoted) return;

    //const userId = user ? user.id : null;

    try {
      const res = await fetch(`${server}/poll/latest/vote`, {
        method: "POST",
        body: JSON.stringify({ optionId: selectedOption._id, userId: userInfo.id }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (res.ok) {
        setUserVoted(true);

        const updatedPoll = { ...poll };
        const votedOption = updatedPoll.options.find(
          (opt) => opt._id === selectedOption._id
        );
        if (votedOption) {
          votedOption.votes += 1;
          setPoll(updatedPoll);
        }
      }
    } catch (error) {
      console.error("Error submitting vote:".error);
    }
  }

  if (!poll) {
    return <div>Loading ...</div>;
  }

  return (
    <div className={classes.container}>
      {isEmpty(userInfo) && (
        <Modal>
          <p className={classes.message}>
            Please&nbsp;
            <span className={classes.link} onClick={() => handleLinkClick('/register')}>
              Register
            </span>&nbsp;
            or&nbsp;
            <span className={classes.link} onClick={() => handleLinkClick('/login')}>
              Log In
            </span>&nbsp;
            to vote
          </p>
        </Modal>
      )}

      {userVoted ? (
        <div className={classes.voted}>
          <p>You've already voted. Thank you for participating!</p>
          <h2>{poll.question}</h2>
          <canvas id="myPieChart" width="400" height="400"></canvas>
        </div>
      ) : (
        <div className={classes.poll}>
          <h2>{poll.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: poll.intro }} />
          <p>{poll.question}</p>
          <div className={classes.options}>
            <h3>Poll Options:</h3>
            {poll.options.map((option) => (
              <div className={classes.option} key={option._id}>
                <input
                  type="radio"
                  id={option._id}
                  name="pollOption"
                  value={option._id}
                  checked={selectedOption === option}
                  onChange={() => setSelectedOption(option)}
                />
                <label htmlFor={option._id}>{option.optionText}</label>
              </div>
            ))}
          </div>
          <button
            className={classes.btn}
            onClick={submitVote}
            disabled={isEmpty(userInfo) || userVoted}
          >
            {userVoted ? "Voted" : "Submit Vote"}
          </button>
        </div>
      )}
    </div>
  );
}

export default LatestPoll;
