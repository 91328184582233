import sunshine from "../data/json/sunshineFAQs.json"
import foundation from "../data/json/foundationFAQs.json"
import FAQ from "../components/FAQ";

function FAQPage() {
    return <div>
        <h1>Frequently Asked Questions (FAQS)</h1>
        <h2 className="subheading" >FAQs about Sunshine</h2>
        <FAQ data={sunshine}/>
        <h2 className="subheading" id="foundation">FAQs about The Sunshine Foundation</h2>
        <FAQ data={foundation}/> 
    </div>;
}

export default FAQPage;
