import ProductPreview from "../components/ProductPreview";
import PageHeader from "../components/PageHeader"

function HowWeShinePage() {
    return <div>
        <PageHeader text="How We Shine" />
        <ProductPreview />
    </div>;
}

export default HowWeShinePage;
