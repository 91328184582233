import { useEffect, useRef, useState } from "react";

import classes from "./NavBtn.module.css";
import NavMenu from "./NavMenu";
import NavBtnImg from "../assets/NavBtn.svg"

function NavBtn() {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const ref = useRef(null);
    
    function toggleMenuHandler() {
        setMenuIsOpen(!menuIsOpen)
    }

    useEffect(() => {
        const handleOutsideClick = (ev) => {
            if (ref.current && !ref.current.contains(ev.target)) {
                setMenuIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [menuIsOpen]);

    return (
        <div ref={ref}>
            <button className={classes.navbtn} onClick={toggleMenuHandler}>
                <img src={NavBtnImg} alt="NavBtn" />
            </button>
            <div className={classes.dropdown}>
                {menuIsOpen && (
                    <NavMenu onClick={toggleMenuHandler}/>
                )}
            </div>
        </div>
    );
}

export default NavBtn
