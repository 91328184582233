import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import HomePage from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AboutPage from "./pages/About";
import HowWeShinePage from "./pages/HowWeShine";
import SunShineFoundationPage from "./pages/SunShineFoundation";
import BlogPage from "./pages/Blog";
import CreateBlogPage from "./pages/CreateBlog";
import BlogPostPage from "./pages/BlogPostPage";
import EditBlogPage from "./pages/EditBlog";
import SupportPage from "./pages/Support";
import CreatePollPage from "./pages/CreatePoll";
import FAQPage from "./pages/FAQ";
import ContactPage from "./pages/Contact";
import PDFViewerPage from "./pages/PDFViewerPage";

import "./index.css";
import "./fonts/RobotoFlex_Variable.ttf";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/howweshine" element={<HowWeShinePage />} />
      <Route path="/sunshinefoundation" element={<SunShineFoundationPage />} />
      <Route path="/news" element={<BlogPage />} />
      <Route path="/create" element={<CreateBlogPage />} />
      <Route path="/post/:id" element={<BlogPostPage />} />
      <Route path="/edit/:id" element={<EditBlogPage />} />
      <Route path="/support" element={<SupportPage />} />
      <Route path="/poll" element={<CreatePollPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/foundation-documents" element={<PDFViewerPage />} />
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*
<BrowserRouter>
  <App />
</BrowserRouter>
*/
reportWebVitals();
