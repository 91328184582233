import PageHeader from "../components/PageHeader";
import TeamBioList from "../components/TeamBioList";
import TextBlock from "../components/TextBlock";

import mission from "../data/json/mission-statement.json";

function AboutPage() {
  return (
    <div>
      <PageHeader text="About Sunshine" />
      <h2 className="subheading">Mission Statement</h2>
      <TextBlock text={mission.mission} />
      <h2 className="subheading" id="team">Meet the Team</h2>
        <TeamBioList/>
    </div>
  );
}

export default AboutPage;
