import { Outlet, Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AboutPage from "./pages/About";
import HowWeShinePage from "./pages/HowWeShine";
import SunShineFoundationPage from "./pages/SunShineFoundation";
import BlogPage from "./pages/Blog";
import CreateBlogPage from "./pages/CreateBlog";
import BlogPostPage from "./pages/BlogPostPage";
import EditBlogPage from "./pages/EditBlog";
import SupportPage from "./pages/Support";
import CreatePollPage from "./pages/CreatePoll";
import FAQPage from "./pages/FAQ";
import ContactPage from "./pages/Contact";

import Header from "./components/Header";
import Footer from "./components/Footer";

import { UserContextProvider } from "./UserContext";

import "./App.css";

function App() {
  return (
    <UserContextProvider>
      <div className="App">
        <div className="App-header">
          <Header />
        </div>
        <Outlet />
        <footer>
          <Footer />
        </footer>
      </div>
    </UserContextProvider>
  );
}

/*
<Routes>
  <Route path="/" element={<HomePage />} />
  <Route path="/login" element={<LoginPage />} />
  <Route path="/register" element={<RegisterPage />} />
  <Route path="/about" element={<AboutPage />} />
  <Route path="/howweshine" element={<HowWeShinePage />} />
  <Route
    path="/sunshinefoundation"
    element={<SunShineFoundationPage />}
  />
  <Route path="/news" element={<BlogPage />} />
  <Route path="/create" element={<CreateBlogPage />} />
  <Route path="/post/:id" element={<BlogPostPage />} />
  <Route path="/edit/:id" element={<EditBlogPage />} />
  <Route path="/support" element={<SupportPage />} />
  <Route path="/poll" element={<CreatePollPage />} />
  <Route path="/faq" element={<FAQPage />} />
  <Route path="/contact" element={<ContactPage />} />
</Routes>
*/

export default App;
