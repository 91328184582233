import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { UserContext } from "../UserContext";
import { ReactComponent as LineIcon } from "../assets/horizontalLineWIcon.svg";
import EditBtn from "../components/EditBtn";
import BackButton from "../components/BackButton";

function BlogPostPage() {
  const [postInfo, setPostInfo] = useState(null);
  const { userInfo } = useContext(UserContext);
  const { id } = useParams();
  // const server = "http://localhost:4000";
  // const server = "https://sunshine-website-staging.onrender.com";
  const server = process.env.REACT_APP_SERVER;

  useEffect(() => {
    fetch(`${server}/post/${id}`).then((res) => {
      res.json().then((postInfo) => {
        setPostInfo(postInfo);
      });
    });
  }, [id]);

  if (!postInfo) return "";

  return (
    <div className="postPage">
      <div className="backBtnContainer">
        <BackButton path={"news"} className="backBtn" />
      </div>
      <div className="blogContainer">
        <h1 className="blogTitle">{postInfo.title}</h1>
        <div className="lineIconContainer">
          <LineIcon className="lineIcon" />
        </div>
        <div className="aboveImg">
          <time className="time">
            {format(new Date(postInfo.createdAt), " MMM d, yyyy HH:mm")}
          </time>
          <div className="vLine">|</div>
          <div className="blogAuthor">By @{postInfo.author.username}</div>
        </div>
        {userInfo && userInfo.role === "admin" && (
          <EditBtn postInfo={postInfo} className="editBtn" />
        )}
        <div className="blogImage">
          <img src={`${postInfo.cover}`} alt="" />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: postInfo.content }}
          className="blogContent"
        />
      </div>
    </div>
  );
}

export default BlogPostPage;
