import BlogPost from "./BlogPost";
import classes from "./LatestBlog.module.css"
import { useState, useEffect } from "react"

function LatestBlog() {
    const [posts, setPosts] = useState([]);
    // const server = "http://localhost:4000";
    // const server = "https://sunshine-website-staging.onrender.com";
    const server = process.env.REACT_APP_SERVER;

  useEffect(() => {
    fetch(`${server}/post`).then((res) => {
      res.json().then((posts) => {
        setPosts(posts);
      });
    });
  }, []);

  const latestPost = posts.length > 0 ? posts[0] : null;

    return (
        <div className={classes.blog}>
            {latestPost && (
                <div className={classes.post}>
                    <h1 className={classes.subheading} >Latest From Sunshine</h1>
                    <BlogPost {...latestPost} vert={true}/>
                </div>
            )}
        </div>
    );
}

export default LatestBlog;