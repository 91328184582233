import BlogPost from "./BlogPost";
import { useEffect, useState } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import classes from "./BlogList.module.css";

function BlogList() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const limit = 5;
  const server = process.env.REACT_APP_SERVER;

  useEffect(() => {
    fetch(`${server}/post/count`) // Replace with your actual endpoint
      .then((res) => res.json())
      .then((data) => setTotalPosts(data.count)); // Replace 'count' with the actual key in your response
  }, []);

  useEffect(() => {
    fetch(`${server}/post?page=${currentPage}&limit=${limit}`)
      .then((res) => res.json())
      .then((data) => setPosts(data));
  }, [currentPage]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const lastPage = Math.ceil(totalPosts / limit);

  return (
    <div>
      {posts.length > 0 &&
        posts.map((post) => <BlogPost key={post._id} {...post} />)}
      <div className={classes.pages}>
        {currentPage > 1 && (
          <FaArrowAltCircleLeft onClick={handlePreviousPage} className={classes.leftArrow}/>
        )}
        <span className={classes.label}>
          Page {currentPage} / {lastPage}
        </span>
        {currentPage < lastPage && (
          <FaArrowAltCircleRight onClick={handleNextPage} className={classes.rightArrow}/>
        )}
      </div>
    </div>
  );
}

export default BlogList;
