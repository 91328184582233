import { useNavigate } from "react-router-dom";
import classes from "./LoginBtn.module.css";

function LoginBtn() {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        const currentPath = window.location.pathname;
        localStorage.setItem('redirectPath', currentPath);
        navigate('/login');
    }

    return (
        <button className={classes.btn} onClick={handleLoginClick}>
            Login
        </button>
    );
}

export default LoginBtn;
