import classes from "./ContactForm.module.css";

import { useState, useRef } from "react";
import emailjs from "@emailjs/browser"

function ContactForm() {
    const form = useRef();
    const [message, setMessage] = useState(null);

    function SendEmail(e) {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_gxvgvai",
                "template_kp2xwni",
                form.current,
                "ztgDdTB1Ozj5qdwgZ"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    console.log("Message Sent");
                    e.target.reset();
                    setMessage("Message Sent");
                    setTimeout(() => {
                        setMessage(null);
                    }, 3000);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <div className={classes.container}>
            <form className={classes.form} ref={form} onSubmit={SendEmail}>
                <h3>Contact Us</h3>
                <input type="text" name="user_name" placeholder="Name" />
                <input type="email" name="user_email" placeholder="Email"/>
                <textarea name="message" placeholder="Type Message Here"/>
                <input className={classes.submit} type="submit" value="Send" />
            </form>
            {message && (
                <div className={classes.successMessage}>
                    {message}
                </div>
            )}
        </div>
    );
}

export default ContactForm;