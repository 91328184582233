import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";

import classes from "./EditBtn.module.css";

function EditBtn(props) {
    const { postInfo } = props;

  return (
    <Link className={classes.editBtn} to={`/edit/${postInfo._id}`}>
      <FaRegEdit className={classes.editIcon} />
      <div className={classes.editBtnText}>
        Edit
      </div>
    </Link>
  );
}

export default EditBtn;
