import { BioData } from "../data/BioData";
import TeamBio from "./TeamBio";

import classes from "./TeamBioList.module.css";

function TeamBioList() {
  const bios = BioData;
  return (
    <div className={classes.bioList}>
      {bios.length > 0 &&
        bios.map((bio, index) => (
          <TeamBio
            key={bio.id}
            name={bio.name}
            username={bio.username}
            headshot={bio.headshot}
            body={bio.body}
            socialLinks={bio.socialLinks}
            layout={index & 1}
          />
        ))}
    </div>
  );
}

export default TeamBioList;
