import { useNavigate } from "react-router-dom";
import classes from "./RegisterBtn.module.css";

function RegisterBtn() {
    const navigate = useNavigate();

    const handleRegisterClick = () => {
        const currentPath = window.location.pathname;
        localStorage.setItem('redirectPath', currentPath);
        navigate('/register');
    }

    return (
        <button className={classes.btn} onClick={handleRegisterClick}>
            Register
        </button>
    );
}

export default RegisterBtn;