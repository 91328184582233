import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

import classes from "./BackButton.module.css"

function BackButton({path}) {
  return (
    <Link className={classes.link} to={`/${path}`}>
      <div className={classes.backBtn}>
        <FaAngleLeft />
        Back to News
      </div>
    </Link>
  );
}

export default BackButton;