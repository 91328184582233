import { Link } from "react-router-dom";

import classes from "./MainNavigation.module.css";

function MainNavigation(props) {
  const { onClick } = props;

  return (
    <header className={classes.header}>
      <nav>
        <ul className={classes.ul}>
          <li className={classes.li}>
            <Link to="/about" className={classes.link} onClick={onClick}>
              About
            </Link>
          </li>
          <li>
            <Link to="/howweshine" className={classes.link} onClick={onClick}>
              How We Shine
            </Link>
          </li>
          <li>
            <Link
              to="/sunshinefoundation"
              className={classes.link}
              onClick={onClick}
            >
              The Sunshine Foundation
            </Link>
          </li>
          <li>
            <Link to="/news" className={classes.link} onClick={onClick}>
              News/Updates
            </Link>
          </li>
          <li>
            <Link to="/support" className={classes.link} onClick={onClick}>
              Support Us
            </Link>
          </li>
          <li>
            <Link to="/faq" className={classes.link} onClick={onClick}>
              FAQs
            </Link>
          </li>
          <li>
            <Link to="/contact" className={classes.link} onClick={onClick}>
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MainNavigation;
