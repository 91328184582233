import ImageSlider from "./ImageSlider";
import { sunshineSlides } from "../data/SunShinePreviewSlider";
import sunshineDesc from "../data/json/sunshine-desc.json"

import classes from "./ProductPreview.module.css";
import TextBlock from "./TextBlock";



function ProductPreview() {
  return (
    <div className={classes.container}>
      <TextBlock  className={classes.text} text={sunshineDesc.desc} />
      <ImageSlider slides={sunshineSlides} />
    </div>
  );
}

export default ProductPreview;
