import classes from "./PageHeader.module.css";

function PageHeader({ text }) {
    return (
        <div className={classes.pageHeader}>
            {text}
        </div>
    );
}

export default PageHeader;