import Socials from "./Socials";
import { SocialLinks } from "../data/SocialLinks";
import Copy from "./Copy";

import classes from "./Footer.module.css";
import UserCount from "./UserCount";

function Footer() {
  return (
    <div className={classes.footer}>
      <div className={classes.leftSide}>
        <UserCount goalAmount={100} />
      </div>
      <div className={classes.rightSide}>
        <Copy className={classes.copy} />
        <Socials socialLinks={SocialLinks} className={classes.socials} />
      </div>
    </div>
  );
}

export default Footer;
