import brando from "../assets/headshots/brando.jpeg";
import presty from "../assets/headshots/presty.jpeg";
import ryan from "../assets/headshots/ryan.jpeg";
import parker from "../assets/headshots/parker.jpeg";
import brandoBio from "../data/biographies/brandon-larue.json";
import prestyBio from "../data/biographies/preston-decker.json";
import ryanBio from "../data/biographies/ryan-spangler.json";
import parkerBio from "../data/biographies/parker-decker.json";

export const BioData = [
  {
    id: 1,
    name: "Brandon LaRue",
    username: "brando",
    headshot: brando,
    body: brandoBio.biography,
    socialLinks: [
      {
        platform: "instagram",
        link: "https://www.instagram.com/_brandonlarue/",
      },
      { platform: "twitter", link: "https://twitter.com/ya_boi_brando" },
      {
        platform: "linkedin",
        link: "https://www.linkedin.com/in/brandon-larue/",
      },
    ],
  },
  {
    id: 2,
    name: "Preston Decker",
    username: "presty",
    headshot: presty,
    body: prestyBio.biography,
    socialLinks: [
      {
        platform: "instagram",
        link: "https://www.instagram.com/preston_decker/",
      },
      { platform: "twitter", link: "https://twitter.com/Preston_decker1" },
      {
        platform: "linkedin",
        link: "https://www.linkedin.com/in/preston-decker/",
      },
    ],
  },
  {
    id: 3,
    name: "Ryan Spangler",
    username: "ryan",
    headshot: ryan,
    body: ryanBio.biography,
    socialLinks: [
      {
        platform: "instagram",
        link: "https://www.instagram.com/r_yanspangler/",
      },
      { platform: "twitter", link: "https://twitter.com/spanglerryan" },
      {
        platform: "linkedin",
        link: "https://www.linkedin.com/in/ryan-spangler-284100224/",
      },
    ],
  },
  {
    id: 4,
    name: "Parker Decker",
    username: "parker",
    headshot: parker,
    body: parkerBio.biography,
    socialLinks: [
      {
        platform: "instagram",
        link: "https://www.instagram.com/parker.decker/",
      },
      { platform: "twitter", link: "https://twitter.com/Parker_Decker12" },
      {
        platform: "linkedin",
        link: "https://www.linkedin.com/in/parker-decker-duke-student/",
      },
    ],
  },
];
