import classes from "./ProgressBar.module.css";

function ProgressBar(props) {
  const { current, goal } = props;
  const percentComplete = (current / goal) * 100;

  const progressStyle = {
    "--progress-width": `${percentComplete}%`,
  };

  return (
    <div className={classes.progressBar}>
      <div className={classes.container}>
        <div className={classes.progress} style={progressStyle}>
          <span className={classes.label}>{`$${current}`}</span>
        </div>
      </div>
      <div className={classes.goal}>{`$${goal}`}</div>
    </div>
  );
}

export default ProgressBar;
