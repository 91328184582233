import { useEffect, useRef, useState } from "react";

import AdminMenu from "./AdminMenu";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

import classes from "./AdminOptionsButton.module.css";

function AdminOptionsButton() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const ref = useRef(null);

  function toggleMenuHandler() {
    setMenuIsOpen(!menuIsOpen);
  }

  useEffect(() => {
    const handleOutsideClick = (ev) => {
      if (ref.current && !ref.current.contains(ev.target)) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menuIsOpen]);

  return (
    <div className={classes.container} ref={ref}>
      <button className={classes.optionBtn} onClick={toggleMenuHandler}>
        Admin Options
        {menuIsOpen ? (
          <FaAngleDown className={classes.arrow} />
        ) : (
          <FaAngleRight className={classes.arrow} />
        )}
      </button>
      <div className={classes.dropdown}>
        {menuIsOpen && <AdminMenu onClick={toggleMenuHandler} />}
      </div>
    </div>
  );
}

export default AdminOptionsButton;
