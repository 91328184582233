import sunshineHome from '../assets/sunshinePreview/sunshineHome.png'
import sunshineSelfie from '../assets/sunshinePreview/sunshineSelfie.png'
import sunshinePost from '../assets/sunshinePreview/sunshinePost.png'
import sunshineFeed from '../assets/sunshinePreview/sunshineFeed.png'
import sunshineNotis from '../assets/sunshinePreview/sunshineNotis.png'

export const sunshineSlides = [
    {id: sunshineHome, alt: 'Home', caption: 'Find Inspiration'},
    {id: sunshineSelfie, alt: 'Selfie', caption: 'Snap Your Smile Anywhere'},
    {id: sunshinePost, alt: 'Post', caption: 'Radiate Your Positivity'},
    {id: sunshineFeed, alt: 'Feed', caption: 'Support Your Friends'},
    {id: sunshineNotis, alt: 'Notis', caption: 'See What Others Have to Say'}
];