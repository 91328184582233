function PDFViewerPage() {
  return (
    <div>
      <h1>The Sunshine Foundation Public Documents</h1>
      <h2 className="subheading">Select a document to view</h2>
      <ul className="ul">
        <li>
          <a
            className="link"
            href="/pdfs/articlesOfIncorporation.pdf"
            target="_blank"
            rel="noopen noreferrer"
          >
            Articles of Incorporation
          </a>
        </li>
        <li>
          <a
            className="link"
            href="/pdfs/bylaws.pdf"
            target="_blank"
            rel="noopen noreferrer"
          >
            Bylaws
          </a>
        </li>
        <li>
          <a
            className="link"
            href="/pdfs/conflictOfInterestPolicy.pdf"
            target="_blank"
            rel="noopen noreferrer"
          >
            Conflict of Interest Policy
          </a>
        </li>
        <li>Form 1023</li>
        <li>Form 990: Coming Soon</li>
        <li>Form 990-T: Coming Soon</li>
        <li>
          <a
            className="link"
            href="/pdfs/contributionForm.pdf"
            target="_blank"
            rel="noopen noreferrer"
          >
            Contribution Form
          </a>
        </li>
        {/* Add more documents and links*/}
      </ul>
    </div>
  );
}

export default PDFViewerPage;
