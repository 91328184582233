import IntroList from "../components/IntroList";
import LatestBlog from "../components/LatestBlog";
import introData from "../data/json/intro.json";
import { ReactComponent as IconWText } from "../assets/IconWText.svg";
import { Link } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa";
import ScrollingNav from "../components/ScrollingNav";

function HomePage() {
  return (
    // <ScrollingNav pages={['/', '/about', '/howweshine', '/sunshinefoundation', '/news', '/support', '/faq', '/contact']}>
      <div id="container">
      <h1 className="homeTitle">Welcome to Sunshine</h1>
      <h1 className="tagline">
        Illuminating Your Path to Holistic Tranformation!
      </h1>
      <IconWText className="iconWText" />
      <IntroList data={introData} />
      <div className="cta">
        <h2 className="item">
          Join the Movement & Radiate Positivity on the World
        </h2>
        <FaArrowDown size={50} className="item" />
        <button className="homeRegisterButton">
          <Link to="/register" className="link">
            Reserve your username here!
          </Link>
        </button>
        <h1 className="centeredSub">Together, we rise &#x2600;&#xfe0f;</h1>
      </div>
      <LatestBlog />
    </div>
    // </ScrollingNav>
  );
}

export default HomePage;
